<template>
  <el-dialog
    title="编辑权益"
    :visible.sync="show"
    width="90%"
    :before-close="handleClose"
    append-to-body
    @open="onOpen"
    @opened="opOpened"
  >
    <div class="equity_main wd-flex wd-flex-between">
      <div
        v-show="skuType !== 'show'"
        class="left"
      >
        <search-panel
          flex
          show-btn
          :is-entry="false"
          @getList="getList('reset')"
        >
          <div>
            <p>商品ID</p>
            <el-input
              v-model.trim="searchData.goodIds"
              clearable
              size="mini"
              placeholder="商品ID"
              @clear="getList"
            />
          </div>
          <div>
            <p>商品名称</p>
            <el-input
              v-model.trim="searchData.goodName"
              clearable
              size="mini"
              placeholder="商品名称"
              @clear="getList"
            />
          </div>
          <div>
            <p>店铺名称</p>
            <el-input
              v-model.trim="searchData.storeName"
              clearable
              size="mini"
              placeholder="店铺名称"
              @clear="getList"
            />
          </div>
        </search-panel>
        <el-table
          ref="tableRef"
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%"
          row-key="goodsId"
          @selection-change="selectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column
            label="商品信息"
          >
            <template slot-scope="{row}">
              <GoodsContentCard
                :id="row.goodsId"
                :image="row.coverImgUrl"
                :name="row.goodName"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="店铺名称"
            width="180px"
          >
            <template slot-scope="{row}">
              {{ row.storeName }}
            </template>
          </el-table-column>

          <el-table-column
            label="商品状态"
            width="100px"
          >
            <template slot-scope="{row}">
              <span
                v-if="row.isOnSale === 1"
                class="green"
              >上架</span>
              <span v-else>下架</span>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          v-if="tableData && tableData.length > 0"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagination.nowPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.count"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div :class="['right', {'width100': skuType === 'show'}]">
        <div class="content margin-b20">
          <el-form
            ref="form"
            label-width="110px"
          >
            <el-form-item
              label="权益名称"
              required
            >
              <el-input
                v-model="skuEquity.name"
                placeholder="请输入权益名称"
                size="small"
                maxlength="18"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="权益说明"
              required
            >
              <el-input
                v-model="skuEquity.description"
                placeholder="例：小番茄、桑葚二选一；每次消费仅限使用1次权益"
                size="small"
                maxlength="500"
                show-word-limit
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              label="可兑换规格数"
              required
            >
              <el-input-number
                v-model="skuEquity.changeSkuNum"
                disabled
                size="small"
                :controls="false"
                :min="1"
              />
              件
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <div class="wd-flex wd-flex-between margin-b10">
            <span>已选择可兑换商品 {{ selectTableData.length }} 件</span>
            <el-link
              v-if="skuType !== 'show'"
              type="primary"
              @click="clearTable('clear')"
            >
              清空
            </el-link>
          </div>
          <el-table
            :data="selectTableData"
            border
            style="width: 100%"
            row-key="goodsId"
            max-height="800px"
          >
            <el-table-column
              label="商品信息"
            >
              <template slot-scope="{row}">
                <GoodsContentCard
                  :id="row.goodsId"
                  :image="row.coverImgUrl"
                  :name="row.goodName"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="店铺名称"
              width="180px"
            >
              <template slot-scope="{row}">
                {{ row.storeName }}
              </template>
            </el-table-column>

            <el-table-column
              label="商品状态"
              width="80px"
            >
              <template slot-scope="{row}">
                <span
                  v-if="row.isOnSale === 1"
                  class="green"
                >上架</span>
                <span v-else>下架</span>
              </template>
            </el-table-column>

            <el-table-column
              v-if="skuType !== 'show'"
              label="操作"
              width="100px"
            >
              <template slot-scope="{row}">
                <el-link
                  type="danger"
                  :underline="true"
                  @click="clearTable('self', row)"
                >
                  移除
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-button
          v-if="skuType !== 'show'"
          class="wd-float-right margin-t20"
          type="primary"
          size="small"
          @click="onSubmit"
        >
          保存权益
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import {
  ref, reactive, watch, getCurrentInstance,
} from 'vue';
import GoodsContentCard from '@/components/common/goodsContentCard/index.vue';

const app = getCurrentInstance().proxy;

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  skuId: {
    type: Number,
    default: '',
  },
  eruityInfo: {
    type: Object,
    default: () => {},
  },
  skuType: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['hide', 'getList']);
// ref
const loading = ref(false);
const tableData = ref([]);
const tableRef = ref(null);
const selectTableData = ref([]);
// reactive
// 权益名称
const skuEquity = reactive({
  name: '',
  description: '',
  changeSkuNum: 1,
  skuId: props.skuId,
  goodsId: app.$route.query.goodsId,
});
// 搜索
const searchData = reactive({
  goodIds: '',
  goodName: '',
  storeName: '',
});
const pagination = reactive({
  currentPage: 1,
  nowPageSize: 10,
  count: 0,
});

watch(() => props.show, (val) => {
  if (val) {
    console.log('弹窗show');
  }
});
function onOpen() {
  console.log('onOpen');
  skuEquity.name = '';
  skuEquity.description = '';
  skuEquity.skuId = props.skuId;
}
function opOpened() {
  console.log('opOpened');
  tableRef.value.clearSelection(); // 清空选中
  selectTableData.value = [];
  if (props.eruityInfo) {
    for (let key in props.eruityInfo.skuEquity) {
      skuEquity[key] = props.eruityInfo.skuEquity[key];
    }
    console.log(skuEquity, 9999);
    selectTableData.value = props.eruityInfo.equityScopeList;
    selectTableData.value.forEach((item) => {
      tableRef.value.toggleRowSelection(item, true);
    });
  }
  getList('reset');
}
function handleClose() {
  emit('hide');
}
// 分页控制
function handleSizeChange(val) {
  pagination.nowPageSize = val;
  pagination.currentPage = 1;
  getList();
}
function handleCurrentChange(val) {
  pagination.currentPage = val;
  getList();
}
async function getList(type) {
  if (type === 'reset') {
    pagination.currentPage = 1;
  }
  loading.value = true;
  try {
    const res = await app.$axios.post(app.$api.equity.getGoodList, {
      currentPage: pagination.currentPage,
      pageSize: pagination.nowPageSize,
      goodIds: searchData.goodIds ? searchData.goodIds.split(',') : undefined,
      goodName: searchData.goodName,
      storeName: searchData.storeName,
    });
    if (res.code === 0) {
      tableData.value = res.data.records;
      pagination.count = Number(res.data.total);
      console.log(props.eruityInfo, 888);
    }
  } catch (err) {

  } finally {
    loading.value = false;
  }
}
function selectionChange(selection) {
  selectTableData.value = selection;
}
function onSubmit() {
  if (!skuEquity.name) {
    app.$message.error('请输入权益名称');
    return;
  }
  if (!skuEquity.description) {
    app.$message.error('请输入权益说明');
    return;
  }
  if (!selectTableData.value.length) {
    app.$message.error('请选择商品');
    return;
  }
  app.$confirm('是否确认保存?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let params = {
        equityScopeList: selectTableData.value,
        skuEquity,
      };
      let api = props.eruityInfo ? app.$api.equity.updateGoodEquity : app.$api.equity.addGoodEquity;
      const res = await app.$axios.post(api, params);
      if (res.code === 0) {
        app.$message.success('操作成功！');
        skuEquity.name = '';
        skuEquity.description = '';
        skuEquity.skuId = '';
        emit('hide');
        emit('getList');
      }
    })
    .catch(() => {});
}
function clearTable(type, row) {
  app.$confirm('是否确认操作?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      if (type === 'clear') {
        tableRef.value.clearSelection(); // 清空选中
      } else {
        const cheakArray = tableRef.value.selection;
        console.log(cheakArray, '选中数据');
        const find = cheakArray.find((fin) => fin.goodsId === row.goodsId);
        console.log(find);
        tableRef.value.toggleRowSelection(find, false);
      }
    })
    .catch(() => {});
}
</script>

<style lang="scss" scoped>
.equity_main {
  .left {
    width: 49%;
  }
  .right {
    width: 49%;
    .content {
      padding: 10px;
      border: 1px solid #ccc;
      box-shadow: 0 2px 4px rgba(2, 2, 2, 0.3);
    }
  }
  .width100 {
    width: 100%;
  }
}
</style>
