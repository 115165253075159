var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods_box" },
    [
      _vm.image
        ? _c("el-image", {
            staticClass: "goods_img",
            attrs: {
              fit: "cover",
              src: _vm.image.split(",")[0],
              "preview-src-list": _vm.image.split(","),
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "goods_content" },
        [
          _vm.name
            ? _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.name,
                    placement: "top",
                  },
                },
                [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(" " + _vm._s(_vm.name) + " "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.storeName
            ? _c("div", { staticClass: "shop" }, [
                _vm._v(" 店铺：" + _vm._s(_vm.storeName) + " "),
              ])
            : _vm._e(),
          _vm.id
            ? _c(
                "div",
                { staticClass: "shop" },
                [
                  _vm._v(" 商品ID：" + _vm._s(_vm.id) + " "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.copyText(_vm.id)
                        },
                      },
                    },
                    [_vm._v(" 复制 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tag
            ? _c(
                "div",
                _vm._l(_vm.tag.split(","), function (item, i) {
                  return _c(
                    "el-tag",
                    {
                      key: i,
                      staticClass: "margin-r5",
                      attrs: { size: "mini", effect: "dark", type: "" },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }