<template>
  <el-dialog
    title="权益列表"
    :visible.sync="show"
    width="90%"
    :before-close="handleClose"
  >
    <div>
      <div>
        <el-button
          v-if="skuType !== 'show'"
          class="margin-b10"
          size="small"
          @click="operation('add')"
        >
          添加权益
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          label="权益名称"
        >
          <template slot-scope="{row}">
            {{ row.skuEquity?.name }}
          </template>
        </el-table-column>
        <el-table-column
          label="权益说明"
        >
          <template slot-scope="{row}">
            {{ row.skuEquity?.description }}
          </template>
        </el-table-column>
        <el-table-column
          label="可兑现规格数"
        >
          <template slot-scope="{row}">
            {{ row.skuEquity?.changeSkuNum }}
          </template>
        </el-table-column>
        <el-table-column
          label="可兑现商品数"
        >
          <template slot-scope="{row}">
            {{ row.equityScopeList?.length }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
        >
          <template slot-scope="{row}">
            <el-link
              type="primary"
              :underline="false"
              @click="operation('edit', row)"
            >
              {{ skuType === 'show' ? '查看' : '编辑' }}
            </el-link>
            <template v-if="skuType !== 'show'">
              <el-divider direction="vertical" />
              <el-link
                type="danger"
                :underline="false"
                @click="operation('delete', row)"
              >
                删除
              </el-link>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <EquityEditDialog
      :show="showEquityEdit"
      :sku-id="props.skuId"
      :sku-type="skuType"
      :eruity-info="eruityInfo"
      @hide="showEquityEdit = false"
      @getList="onSucess"
    />
  </el-dialog>
</template>

<script setup>
import {
  ref, watch, getCurrentInstance, reactive,
} from 'vue';
import EquityEditDialog from './EquityEditDialog.vue';

const app = getCurrentInstance().proxy;

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  skuId: {
    type: Number,
    default: undefined,
  },
  skuType: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['hide', 'getList']);
const showEquityEdit = ref(false);
const tableData = ref([]);
const eruityInfo = ref(null);

watch(() => props.show, (val) => {
  if (val && props.skuId) {
    getEquityList(props.skuId);
  }
});
async function getEquityList(skuId) {
  const res = await app.$axios.get(app.$api.equity.getGoodEquityList, {
    params: {
      skuId,
    },
  });
  if (res.code !== 0) return;
  tableData.value = res.data;
}
function handleClose() {
  emit('hide');
}
// 点击添加权益
function operation(type, row) {
  if (type === 'edit') {
    eruityInfo.value = row;
    showEquityEdit.value = true;
  } else if (type === 'add') {
    if (tableData.value.length >= 10) {
      app.$message.error('最多添加10个权益');
      return;
    }
    eruityInfo.value = null;
    showEquityEdit.value = true;
  } else if (type === 'delete') {
    app.$confirm('是否确认删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        const res = await app.$axios.post(app.$api.equity.deleteGoodEquity, {
          equityId: row.skuEquity.equityId,
          goodIds: row.skuEquity.goodIds,
        });
        if (res.code !== 0) return;
        app.$message.success('操作成功！');
        getEquityList(props.skuId);
        emit('getList');
      })
      .catch(() => {});
  }
}
function onSucess() {
  getEquityList(props.skuId);
  emit('getList');
}
</script>
