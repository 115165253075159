var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "权益列表",
        visible: _vm.show,
        width: "90%",
        "before-close": _setup.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            [
              _vm.skuType !== "show"
                ? _c(
                    "el-button",
                    {
                      staticClass: "margin-b10",
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _setup.operation("add")
                        },
                      },
                    },
                    [_vm._v(" 添加权益 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _setup.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "权益名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_vm._v(" " + _vm._s(row.skuEquity?.name) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "权益说明" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(" " + _vm._s(row.skuEquity?.description) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "可兑现规格数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(" " + _vm._s(row.skuEquity?.changeSkuNum) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "可兑现商品数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(" " + _vm._s(row.equityScopeList?.length) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _setup.operation("edit", row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.skuType === "show" ? "查看" : "编辑"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.skuType !== "show"
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "danger", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _setup.operation("delete", row)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(_setup.EquityEditDialog, {
        attrs: {
          show: _setup.showEquityEdit,
          "sku-id": _setup.props.skuId,
          "sku-type": _vm.skuType,
          "eruity-info": _setup.eruityInfo,
        },
        on: {
          hide: function ($event) {
            _setup.showEquityEdit = false
          },
          getList: _setup.onSucess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }