var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑权益",
        visible: _vm.show,
        width: "90%",
        "before-close": _setup.handleClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _setup.onOpen,
        opened: _setup.opOpened,
      },
    },
    [
      _c("div", { staticClass: "equity_main wd-flex wd-flex-between" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.skuType !== "show",
                expression: "skuType !== 'show'",
              },
            ],
            staticClass: "left",
          },
          [
            _c(
              "search-panel",
              {
                attrs: { flex: "", "show-btn": "", "is-entry": false },
                on: {
                  getList: function ($event) {
                    return _setup.getList("reset")
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品ID")]),
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "商品ID",
                      },
                      on: { clear: _setup.getList },
                      model: {
                        value: _setup.searchData.goodIds,
                        callback: function ($$v) {
                          _vm.$set(
                            _setup.searchData,
                            "goodIds",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "searchData.goodIds",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品名称")]),
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "商品名称",
                      },
                      on: { clear: _setup.getList },
                      model: {
                        value: _setup.searchData.goodName,
                        callback: function ($$v) {
                          _vm.$set(
                            _setup.searchData,
                            "goodName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "searchData.goodName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("店铺名称")]),
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "店铺名称",
                      },
                      on: { clear: _setup.getList },
                      model: {
                        value: _setup.searchData.storeName,
                        callback: function ($$v) {
                          _vm.$set(
                            _setup.searchData,
                            "storeName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "searchData.storeName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _setup.loading,
                    expression: "loading",
                  },
                ],
                ref: "tableRef",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _setup.tableData,
                  border: "",
                  "row-key": "goodsId",
                },
                on: { "selection-change": _setup.selectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    width: "55",
                    "reserve-selection": true,
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "商品信息" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(_setup.GoodsContentCard, {
                            attrs: {
                              id: row.goodsId,
                              image: row.coverImgUrl,
                              name: row.goodName,
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "店铺名称", width: "180px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [_vm._v(" " + _vm._s(row.storeName) + " ")]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "商品状态", width: "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.isOnSale === 1
                            ? _c("span", { staticClass: "green" }, [
                                _vm._v("上架"),
                              ])
                            : _c("span", [_vm._v("下架")]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _setup.tableData && _setup.tableData.length > 0
              ? _c("el-pagination", {
                  attrs: {
                    "current-page": _setup.pagination.currentPage,
                    "page-sizes": [10, 20, 30, 50],
                    "page-size": _setup.pagination.nowPageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _setup.pagination.count,
                  },
                  on: {
                    "size-change": _setup.handleSizeChange,
                    "current-change": _setup.handleCurrentChange,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { class: ["right", { width100: _vm.skuType === "show" }] },
          [
            _c(
              "div",
              { staticClass: "content margin-b20" },
              [
                _c(
                  "el-form",
                  { ref: "form", attrs: { "label-width": "110px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "权益名称", required: "" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入权益名称",
                            size: "small",
                            maxlength: "18",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _setup.skuEquity.name,
                            callback: function ($$v) {
                              _vm.$set(_setup.skuEquity, "name", $$v)
                            },
                            expression: "skuEquity.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "权益说明", required: "" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              "例：小番茄、桑葚二选一；每次消费仅限使用1次权益",
                            size: "small",
                            maxlength: "500",
                            "show-word-limit": "",
                            type: "textarea",
                          },
                          model: {
                            value: _setup.skuEquity.description,
                            callback: function ($$v) {
                              _vm.$set(_setup.skuEquity, "description", $$v)
                            },
                            expression: "skuEquity.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "可兑换规格数", required: "" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            disabled: "",
                            size: "small",
                            controls: false,
                            min: 1,
                          },
                          model: {
                            value: _setup.skuEquity.changeSkuNum,
                            callback: function ($$v) {
                              _vm.$set(_setup.skuEquity, "changeSkuNum", $$v)
                            },
                            expression: "skuEquity.changeSkuNum",
                          },
                        }),
                        _vm._v(" 件 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "div",
                  { staticClass: "wd-flex wd-flex-between margin-b10" },
                  [
                    _c("span", [
                      _vm._v(
                        "已选择可兑换商品 " +
                          _vm._s(_setup.selectTableData.length) +
                          " 件"
                      ),
                    ]),
                    _vm.skuType !== "show"
                      ? _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _setup.clearTable("clear")
                              },
                            },
                          },
                          [_vm._v(" 清空 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _setup.selectTableData,
                      border: "",
                      "row-key": "goodsId",
                      "max-height": "800px",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "商品信息" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(_setup.GoodsContentCard, {
                                attrs: {
                                  id: row.goodsId,
                                  image: row.coverImgUrl,
                                  name: row.goodName,
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "店铺名称", width: "180px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.storeName) + " ")]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "商品状态", width: "80px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.isOnSale === 1
                                ? _c("span", { staticClass: "green" }, [
                                    _vm._v("上架"),
                                  ])
                                : _c("span", [_vm._v("下架")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.skuType !== "show"
                      ? _c("el-table-column", {
                          attrs: { label: "操作", width: "100px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "danger",
                                          underline: true,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _setup.clearTable(
                                              "self",
                                              row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 移除 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            194350253
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.skuType !== "show"
              ? _c(
                  "el-button",
                  {
                    staticClass: "wd-float-right margin-t20",
                    attrs: { type: "primary", size: "small" },
                    on: { click: _setup.onSubmit },
                  },
                  [_vm._v(" 保存权益 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }