<template>
  <div class="goods_box">
    <el-image
      v-if="image"
      class="goods_img"
      fit="cover"
      :src="image.split(',')[0]"
      :preview-src-list="image.split(',')"
    />
    <div class="goods_content">
      <el-tooltip
        v-if="name"
        class="item"
        effect="dark"
        :content="name"
        placement="top"
      >
        <div class="name">
          {{ name }}
        </div>
      </el-tooltip>
      <div
        v-if="storeName"
        class="shop"
      >
        店铺：{{ storeName }}
      </div>
      <div
        v-if="id"
        class="shop"
      >
        商品ID：{{ id }}
        <el-button
          type="text"
          size="small"
          @click="copyText(id)"
        >
          复制
        </el-button>
      </div>
      <div v-if="tag">
        <el-tag
          v-for="(item, i) in tag.split(',')"
          :key="i"
          class="margin-r5"
          size="mini"
          effect="dark"
          type=""
        >
          {{ item }}
        </el-tag>
      </div>
    </div>
  </div>
</template>

<script>
import { copyText } from '../common';

export default {
  name: '',
  components: {},
  props: {
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
    tag: {
      type: String,
      default: '',
    },
    storeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    copyText,
  },
};
</script>

<style scoped lang="scss">
.goods_box {
  display: flex;
  .goods_img {
    width: 70px;
    height: 70px;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 20px;
  }
  .goods_content {
    width: calc(100% - 90px);
    .name {
      line-height: 20px;
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }
    .shop {
      line-height: 18px;
      color: #8f8f8f;
    }
  }
}
</style>
